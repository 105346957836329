import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Explore.css";
import ChartExplore from "./ChartExplore";
import { connect } from "react-redux";

class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.match.params.topic,
      meta_title: this.props.match.params.topic.replaceAll("-", " "),
      meta_description: `We are here to help you find the best ${props.topicTitleS.toLowerCase()} for you. A Product Comparison unlike a Competitor Analysis provides a simple way to compare product features or the product’s capabilities across multiple products. Yet traditional product comparison rarely allows you to determine what is the best x for you.`,
      meta_image: window.location.origin + "/images/logo.png",
      categoryImage: props.categoryImage,
      topicTitleS: props.topicTitleS,
      topicTitleP: props.topicTitleP,
      chartData: props.chartData,
      chartTitle: props.chartTitle,
      meta_url: window.location.href.split("?")[0], //location.href.split('?')[0]
      backgroundImage:
        "https://spy.com/wp-content/uploads/2020/06/best-memory-foam-mattresses-of-2020.jpg?w=1000&h=560&crop=1",
    };
  }

  handleStartClick = (e) => {
    window.location = `/compare/${this.state.link}/1`;
  };

  render() {
    return (
      <div>
        <Helmet>
          {/* Primary Meta Tags */}
          <title>Explore {this.state.topicTitleP} | Exnov</title>
          <meta
            name="title"
            content={"Explore " + this.state.topicTitleP + " | Exnov"}
          />
          <meta name="description" content={this.state.meta_description} />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={this.state.meta_url} />
          <meta
            property="og:title"
            content={"Explore " + this.state.topicTitleP + " | Exnov"}
          />
          <meta
            property="og:description"
            content={this.state.meta_description}
          />
          <meta property="og:image" content={this.state.meta_image} />

          {/* Twitter */}
          <meta property="twitter:card" content={this.state.meta_image} />
          <meta property="twitter:url" content={this.state.meta_url} />
          <meta
            property="twitter:title"
            content={"Explore " + this.state.topicTitleP + " | Exnov"}
          />
          <meta
            property="twitter:description"
            content={this.state.meta_description}
          />
          <meta property="twitter:image" content={this.state.meta_image} />
        </Helmet>
        <div className="mainBody">
          <div className="contentCon" id="explorePage">
            <style>
              {`#explorePage .topicBackground:before {background: url(${this.state.categoryImage});  background-repeat: no-repeat;background-size: 100%, cover;}`}
            </style>

            <div className="topicBackground">
              <h1>
                Let’s find the perfect {this.state.topicTitleS.toLowerCase()}{" "}
                for you.
              </h1>

              <button
                className="bigBtn"
                style={{
                  border: "none",
                }}
                onClick={this.handleStartClick}
              >
                CLICK TO START
              </button>
            </div>
            {this.state.chartData !== "" ? (
              <div class="pieWrap">
                <ChartExplore
                  data={this.state.chartData}
                  title={this.state.chartTitle}
                />
              </div>
            ) : null}

            <p>
              <b>
                Finding the best {this.state.topicTitleS.toLowerCase()} can be
                an overwhelming process.
              </b>{" "}
              Shoppers often find themselves in analysis paralysis due to the
              sheer number of options.
            </p>
            <p>
              Artificial intelligence (AI) makes it possible for us to estimate
              and predict the best possible option for our customers. Just like
              us, machines learn from experience, adjust to new inputs and
              perform human-like tasks. Machine Learning, Deep learning and many
              more algorithms are changing the world, and you are about to
              experience it firsthand!
            </p>

            <p>
              <b>
                We are here to help you find the best{" "}
                {this.state.topicTitleS.toLowerCase()} for you.
              </b>
            </p>

            <p>
              A Product Comparison unlike a Competitor Analysis provides a
              simple way to compare product features or the product’s
              capabilities across multiple products. Yet traditional product
              comparison rarely allows you to determine what is the best x for
              you.
            </p>

            <p>
              This is why we created Exnov.{" "}
              <b>
                We created a fun & exciting customized Product Comparison
                platform.
              </b>{" "}
              At Exnov.com people and organizations can explore and discover the
              perfect products and solutions for them. We are here to help you
              find the perfect solution for you, that is why we invented the
              world’s first truly existing customized comparison platform.
            </p>

            <p>
              We provide a cross-industry,{" "}
              <b>customized consulting service tailored for your needs.</b> We
              give our users true guidance, unlike traditional product
              comparison websites. The experience is easy, enjoyable and most
              importantly accurate and insightful. We are here to help you reach
              your Goals.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const topicId = window.location.href
  .split("?")[0]
  .split("/")
  .filter((i) => i !== "")
  .slice(-1)[0];

const mapStateToProps = (state) => {
  const topicData = state.compare.topics.filter((i) => i.topic === topicId)[0];

  if (topicData === undefined) {
    window.location = `/`;
  }
  const categoryImage =
    topicData.categoryImage !== undefined ? topicData.categoryImage : "";
  const topicTitleS = topicData.topicTitleS;
  const topicTitleP = topicData.topicTitleP;
  const chartData = topicData.chartData;
  const chartTitle = topicData.chartTitle;
  return {
    categoryImage: categoryImage,
    topicTitleS: topicTitleS,
    topicTitleP: topicTitleP,
    chartData: chartData,
    chartTitle: chartTitle,
  };
};

export default connect(mapStateToProps)(Explore);
