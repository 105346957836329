import React, { Component } from "react";
import { HelmetProvider } from "react-helmet-async";
import Nav from "./Nav/Nav";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Explore from "./Explore";
import Contact from "./Contact";
import Terms from "./Terms";
import Privacy from "./Privacy";
import NotFound from "./NotFound";
import Testo from "./Testo";
import Compare from "./Compare";
import Discover from "./discover";
import Predict from "./Predict";
import News from "./News";
import Articles from "./Articles";
import Blog from "./BlogTmp";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import theme from "./theme";
import { /*createGlobalStyle,*/ ThemeProvider } from "styled-components";

import ReactGA from "react-ga";
ReactGA.initialize("UA-187555257-2");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    // var scrlDiChn = document.getElementsByClassName("white");

    return (
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <BrowserRouter>
            <div>
              <Nav />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/explore/:topic/" component={Explore} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/testo" component={Testo} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route
                  exact
                  path="/compare/:topic/:qNumber/"
                  component={Compare}
                />
                <Route exact path="/discover" component={Discover} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/predict/:topic/" component={Predict} />
                <Route exact path="/news/:topic/" component={News} />
                <Route exact path="/articles/:title/" component={Articles} />
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    );
  }
}

export default App;
