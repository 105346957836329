import React, { useContext } from "react";
import { ContextNav } from "./ContextNav";

export default function DesktopNav() {
  // Get the context/state
  const context: any | null = useContext(ContextNav);

  // The main Menu Content
  const menuContent =
    typeof context == "object"
      ? context?.map((menuLink: (string | undefined)[], key: number) => {
          return (
            <li key={key}>
              <a href={menuLink[0]} id="mAc">
                {menuLink[1]}
              </a>
            </li>
          );
        })
      : null;

  return <ul className="right hide-on-med-and-down">{menuContent}</ul>;
}
