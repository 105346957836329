import React from "react";
import "./BlogTmp.css";

export default function App() {
  return (
    <div className="mainBody">
      <div className="contentCon">
        <div id="innerCS">
          <h1 id="ComingSoon">Coming Soon..</h1>
          <img src="../images/lake.gif" alt="" id="csLake" />
          <p>
            Exnov.com is the world’s leading Customized Product Comparison
            platform, where people and organizations can explore and discover
            the perfect customized technology solution for them. We are here to
            help you find the perfect solution for you, that is why we invented
            the world’s first truly existing customized comparison platform. We
            provide a cross-industry, customized consulting service tailored for
            your needs. We give our users true guidance, unlike traditional
            product comparison websites. The experience is easy, enjoyable and
            most importantly accurate and insightful. We are here to help you
            reach your Goals.
          </p>
        </div>
      </div>
    </div>
  );
}
