import React, { Component } from "react";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <div className="mainBody">
        <div className="contentCon">
          <h1>Oops!</h1>
          <img
            className="roboDance"
            alt="page not found"
            src="/images/roboDance.gif"
          ></img>
          <p>We couldn't find the page…</p>
          <a href="/">
            <button id="goBackHome">GO BACK HOME</button>
          </a>
        </div>
      </div>
    );
  }
}

export default NotFound;
