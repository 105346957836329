import React, { Component } from "react";
import { Helmet } from "react-helmet";

class About extends Component {
  state = {
      meta_title:"About | Exnov",
      meta_description:"Fun and Exciting Customized Product Comparison, Exnov.com is the world’s leading Customized Product Comparison platform, where people and organizations can explore and discover the perfect customized technology solution for them.",
      meta_image:window.location.origin+"/images/logo.png",
      meta_url:window.location.href.split('?')[0]//location.href.split('?')[0]
  };
  render() {
    return (
      <div>
        <Helmet>
            {/* Primary Meta Tags */}
            <title>{this.state.meta_title}</title>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={this.state.meta_url} />
            <meta property="og:title" content={this.state.meta_title} />
            <meta property="og:description" content={this.state.meta_description} />
            <meta property="og:image" content={this.state.meta_image} />

            {/* Twitter */}
            <meta property="twitter:card" content={this.state.meta_image} />
            <meta property="twitter:url" content={this.state.meta_url} />
            <meta property="twitter:title" content={this.state.meta_title} />
            <meta property="twitter:description" content={this.state.meta_description} />
            <meta property="twitter:image" content={this.state.meta_image} />

        </Helmet>
        <div className="mainBody">
          <div className="contentCon">
            <h2>Fun &amp; Exciting Customized Product Comparison</h2>
            <p>
            Exnov.com is the world’s leading Customized Product Comparison platform, where people and organizations can explore and discover the perfect customized technology solution for them.
            <br/><br/>
            We are here to help you find the perfect solution for you, that is why we invented the world’s first truly existing customized comparison platform.
            <br/><br/>
            We provide a cross-industry, customized consulting service tailored for your needs. We give our users true guidance, unlike traditional product comparison websites. The experience is easy, enjoyable and most importantly accurate and insightful. We are here to help you reach your Goals.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
