import React, { ContextType, useContext } from "react";
import { ContextNav } from "./ContextNav";

export default function MobileNav() {
  // Get the context/state
  const context = useContext(ContextNav);

  // The main Menu Content
  const menuContent =
    typeof context == "object"
      ? context!.map((menuLink, key) => {
          return (
            <li key={key}>
              <a href={menuLink[0]}>{menuLink[1]}</a>
            </li>
          );
        })
      : null;

  return <ul>{menuContent}</ul>;
}
