import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addAnswer } from "./store/compareSlice";
import QselectComp from "./QselectComp";
import QrangeComp from "./QrangeComp";
import { Helmet } from "react-helmet-async"; // Future develpoment
import styled from "styled-components";
import CompareGlobalStyle from "./CompareGlobalStyle";

import ReactGA from "react-ga";
ReactGA.initialize("UA-187555257-2");

// style
const QuestionTitle = styled.div`
  font-family: "Merriweather", serif;
  text-align: center;
  font-weight: 500;
  font-size: 34px;
  margin-top: 40px;
  padding: 0px 0px 60px;
  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const Compare = (props) => {
  console.log(window.location.pathname.split("/")[1]);
  const topicId = props.match.params.topic;
  const questionNumber = props.match.params.qNumber - 1;

  // Trigger google analytics page view on all pages ecept page 1
  useEffect(() => {
    // require("./compare.css"); // import compare.css only for compare & predict
    if (questionNumber > 0) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [questionNumber]);

  const dispatch = useDispatch();

  const questionData = useSelector((state) => {
    if (state.compare.topics.filter((i) => i.topic === topicId)[0]) {
      return state.compare.topics.filter((i) => i.topic === topicId)[0]
        .allQuestions[questionNumber];
    } else {
      window.location.href = "/";
    }
  });

  const topicIndex = useSelector((state) =>
    state.compare.topics.findIndex((i) => i.topic === topicId)
  );

  const numberOfAnswers = useSelector(
    (state) => state.compare.topics[topicIndex].answers.length
  );

  // Check for all answer reriect to first page if needed
  if (numberOfAnswers !== questionNumber) {
    let firstPage = "/compare/" + topicId + "/" + 1; // is reduced on top
    window.location.href = firstPage;
    return <div></div>;
    // props.history.push(firstPage);
  }

  const handleSubmit = (e, answerValue) => {
    e.preventDefault();
    dispatch(
      addAnswer({
        topicIndex: topicIndex,
        value: answerValue,
      })
    );

    document.getElementById("QselectCompSubmit").disabled = true;

    if (questionData.lastQuestion) {
      let nextPage = "/predict/" + topicId + "/"; // the predictor path
      props.history.push(nextPage);
    } else {
      // Go to next
      let nextPage = "/compare/" + topicId + "/" + (questionNumber + 2); // is reduced on top
      props.history.push(nextPage);
    }
  };

  return (
    <div>
      <CompareGlobalStyle />
      <Helmet>
        <title>Explore {topicId} | Exnov</title>
      </Helmet>
      <img id="operatorImg" src="/images/operator.png" alt="operatorName" />
      <div className="mainBody">
        <div className="contentCon">
          <QuestionTitle id="questionTitle">
            {questionNumber === 0 ? <div>Hey I'm Ella</div> : ""}
            {questionData.question}
          </QuestionTitle>
          {questionData.qType === "select" ? (
            <QselectComp
              questionData={questionData}
              topicId={topicId}
              questionNumber={questionNumber}
              handleSubmit={handleSubmit}
              topicIndex={topicIndex}
            />
          ) : null}

          {questionData.qType === "range" ? (
            <QrangeComp
              questionData={questionData}
              topicId={topicId}
              questionNumber={questionNumber}
              handleSubmit={handleSubmit}
              topicIndex={topicIndex}
            />
          ) : null}
          {questionData.qType === "input" ? "<QinputComp />" : null}
        </div>
      </div>
    </div>
  );
};

export default Compare;
