import React, { useRef } from "react";
import "./QselectComp.css";
import styled from "styled-components";

const SubmitButton = styled.input`
  border: none;
  &:disabled {
    background-color: #e7e7e7;
    box-shadow: none;
  }
`;

const OptionWrap = styled.div`
  width: 350px;
  margin: auto;
  height: 50px;
  max-width: 100%;
  padding: 5px 10px 5px;
  margin-bottom: 16px;
  border-radius: 5px;
  background: #f7f7f7;
  padding-bottom: 15px;
`;

const OptionLable = styled.label`
  margin-left: -10px;
  font-size: 18px;
  color: #4a4a4a;
  padding-bottom: 15px;
`;

const InputRadio = styled.input`
  &[type="radio"]:not(:checked),
  &[type="radio"]:checked {
    position: relative;
    opacity: 1;
    pointer-events: fill;
  }
`;

const Form = styled.form`
  width: 600px;
  max-width: 100%;
  margin: auto;
`;

const QselectComp = ({
  questionData,
  topicId,
  questionNumber,
  handleSubmit,
  topicIndex,
}) => {
  // initiate cheked attribute
  const radioClasses = document.getElementsByClassName("radioClass");
  Object.keys(radioClasses).forEach((i) => {
    radioClasses[i].checked = false;
  });

  let answerValue = null;

  // document.getElementById("QselectCompSubmit").disabled = true;

  const inputSelect = (e) => {
    answerValue = e.target.value;
    // console.log("answerValue is:", answerValue);
    document.getElementById("QselectCompSubmit").disabled = false;
  };

  // useRef Hook for parent click selection
  let Refs = useRef([]);
  // console.log(Refs);

  const addToRefs = (el) => {
    if (el && !Refs.current.includes(el)) {
      Refs.current.push(el);
    }
  };

  return (
    <div>
      <Form
        onSubmit={(e) => {
          handleSubmit(e, answerValue);
        }}
      >
        {questionData.options.map((opt, i) => {
          return (
            <OptionWrap
              id="optionWrap"
              key={i}
              onClick={() => Refs.current[i].click()}
            >
              <InputRadio
                ref={addToRefs}
                type="radio"
                id="radio"
                className="radioClass"
                name="radio"
                value={opt.value}
                onClick={(e) => {
                  inputSelect(e);
                }}
              />
              <OptionLable htmlFor="age3">{opt.text}</OptionLable>
            </OptionWrap>
          );
        })}

        <SubmitButton
          type="submit"
          className="bigBtn"
          id="QselectCompSubmit"
          value="Next"
          disabled
        />
      </Form>
    </div>
  );
};

export default QselectComp;
