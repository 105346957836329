import React, { useState } from "react";
import styled from "styled-components";

const SubmitButton = styled.input`
  border: none;
  &:disabled {
    background-color: #e7e7e7;
    box-shadow: none;
  }
`;

const RangeDiv = styled.div`
  font-family: "Merriweather", serif;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  margin: auto;
  max-width: 800px;
`;

const Form = styled.form`
  width: 600px;
  max-width: 100%;
  margin: auto;
`;

const QrangeComp = ({
  questionData,
  topicId,
  questionNumber,
  handleSubmit,
  topicIndex,
}) => {
  const minVal = questionData.minVal;
  const maxVal = questionData.maxVal;
  const prefix = questionData.prefix;
  const ratio = questionData.ratio;
  const [answerValue, setAnswerValue] = useState(minVal / ratio);

  const rangeChange = (e) => {
    setAnswerValue(e.target.value / ratio);
    document.getElementById("QselectCompSubmit").disabled = false;
  };

  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e, answerValue);
      }}
    >
      <RangeDiv>
        {prefix +
          parseInt(answerValue * ratio)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </RangeDiv>
      <input
        type="range"
        min={minVal}
        max={maxVal}
        id="myRange"
        onChange={(e) => {
          rangeChange(e);
        }}
      />
      <SubmitButton
        type="submit"
        className="bigBtn"
        id="QselectCompSubmit"
        value="Next"
        disabled
      />
    </Form>
  );
};

export default QrangeComp;
