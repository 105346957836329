import React, { useState, useEffect, useRef } from "react";
import { ContextNav } from "./ContextNav";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";

// State type (internal)
interface State {
  compare: {
    compareBtnText: string;
    compareBtnURL: string;
  };
}

function Nav() {
  // Menu State
  const [menuState] = useState([
    ["/", "Home"],
    ["about", "About"],
    ["contact", "Contact"],
  ]);

  // Nav ID State
  const [navID, setNavID] = useState("upNav");

  // Get compare Button text and link
  const compareBtnText = useSelector(
    (state: State) => state.compare.compareBtnText
  );
  const compareBtnURL = useSelector(
    (state: State) => state.compare.compareBtnURL
  );

  // Refs
  const mobileRef = useRef<HTMLDivElement | null>(null);
  const wrapRef = useRef<HTMLDivElement | null>(null);

  // Location
  const location = window.location.href;

  // Menu open
  const handlerOpenMenu = (/*e: MouseEvent*/) => {
    // e.preventDefault();
    document.body.style.overflow = "hidden";

    if (mobileRef?.current?.style?.display === "none") {
      mobileRef.current.style.display = "block";
      wrapRef!.current!.style!.display = "block";
    } else {
      mobileRef!.current!.style.display = "none";
      wrapRef!.current!.style.display = "none";
    }
  };

  // Menu close
  const handleCloseMenu = (/*e: MouseEvent*/) => {
    document.body.style.overflow = "scroll";
    mobileRef!.current!.style.display = "none";
    wrapRef!.current!.style.display = "none";
  };

  // Nabigation + trigger GA event
  const goToURL = (/*e: MouseEvent*/) => {
    ReactGA.event({
      category: "buttonClick",
      action: "navBarClick",
      value: 1,
    });

    window.location.href = compareBtnURL;
  };

  // Scroll listener
  const lowNavFlag = 40;
  const listenScrollEvent = () => {
    if (window.scrollY > lowNavFlag) {
      setNavID("lowNav");
    } else {
      setNavID("upNav");
    }
  };

  // add scroll listener
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  // Start over
  const handleStartOver = () => {
    const restartUrl =
      window.location.href.split("?")[0].split("/").slice(0, -1).join("/") +
      "/1";
    window.location.href = restartUrl.replace("/predict/", "/compare/");
  };

  // Main CTA style
  const mainCtaStyle =
    location.includes("/compare/") || location.includes("/predict/")
      ? "none"
      : "block";

  // Desktop Menu
  const desktopMenu = (
    <nav className="white" id={navID} role="navigation">
      <div className="nav-wrapper container">
        <img
          className="startOver"
          src="/images/reload.svg"
          alt="Start Over"
          onClick={handleStartOver}
        />
        <DesktopNav />
        <a className="logo-container-22" href="/">
          <img className="logo" src="/images/logo.png" alt="logo" />
        </a>

        <a
          href="/#"
          onClick={handlerOpenMenu}
          data-target="nav-mobile"
          className="sidenav-trigger"
        >
          <i className="material-icons">menu</i>
        </a>
        <div
          className="mainCTA"
          onClick={goToURL}
          style={{ display: mainCtaStyle }}
        >
          {compareBtnText}
        </div>
      </div>
    </nav>
  );

  // Mobile menu
  const mobileMenu = (
    <>
      <div className="ad-mobile-22" style={{ display: "none" }} ref={mobileRef}>
        <div id="close-me-s" onClick={handleCloseMenu}>
          X
        </div>
        <MobileNav />
      </div>
      <div
        className="wrap-over"
        style={{ display: "none" }}
        onClick={handleCloseMenu}
        ref={wrapRef}
      ></div>
    </>
  );
  return (
    <ContextNav.Provider value={menuState}>
      {desktopMenu}
      {mobileMenu}
    </ContextNav.Provider>
  );
}

export default Nav;
