import React from "react";
import styled from "styled-components";
import NewsWidget from "./NewsWidget";

export default function NewsTopics() {
  // The main widget content
  const widgetContent = [
    {
      name: "furniture",
      imageLink: "/images/news/news_caegory_furniture.jpeg",
    },
    {
      name: "home gym",
      imageLink: "/images/news/news_caegory_homegym.jpeg",
    },
    {
      name: "home improvement",
      imageLink: "/images/news/news_caegory_homeimprovement.jpeg",
    },
    {
      name: "lighting",
      imageLink: "/images/news/news_caegory_lighting.jpeg",
    },
    {
      name: "air conditioner",
      imageLink: "/images/news/news_category_heatcool.jpeg",
    },
    {
      name: "floor care",
      imageLink: "/images/news/news_caegory_floor.jpeg",
    },
  ];

  // style
  const Container = styled.div`
    /* border: 1px solid #dfe1e5; */
    box-shadow: none;
    border-radius: 24px;
    height: 200px;
    margin-bottom: 40px;
    display: grid;
    grid-auto-flow: column;
    padding: 0px 10px 10px;
    overflow: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const widgets = widgetContent.map((topic) => {
    return <NewsWidget name={topic.name} imageLink={topic.imageLink} />;
  });

  return <Container>{widgets}</Container>;
}
