import { createGlobalStyle } from "styled-components";

const CompareGlobalStyle = createGlobalStyle`
  a#mAc {
  display: none;
}
.mainCTA {
  margin-top: 9px;
  width: 80px;
  margin-right: 5px;
}

a.logo-container-22 {
  margin-left: 0px;
}
img.logo {
  margin-top: 8px;
}

h1#bigTitle {
  margin-top: 0px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

img.contImgTmp {
  margin-left: -25px;
}

nav a.sidenav-trigger {
  position: fixed;
  left: 0px;
}

@media only screen and (min-width: 993px) {
  nav a.sidenav-trigger {
    display: block;
  }
}

nav#upNav {
  box-shadow: 0px 0px 0px 1px #ccc;
}

a.logo-container-22 {
  position: fixed;
  left: 60px;
  top: 7px;
}

img.logo {
  width: 70px;
}

.mainCTA {
  display: none;
}

img#operatorImg {
  position: fixed;
  width: 80px;
  border-radius: 50%;
  z-index: 9;
  left: 50%;
  margin-left: -40px;
  top: 22px;
}

@media only screen and (max-width: 769px) {
  img#operatorImg {
    width: 40px;
    margin-left: -20px;
    top: 43px;
  }
}

@media only screen and (max-width: 600px) {
  img#operatorImg {
    top: 35px;
  }
  img.logo {
    display: none;
  }
}

img.startOver {
  display: block;
  position: fixed;
  z-index: 900;
  width: 15px;
  top: 24px;
  right: 24px;
}

@media only screen and (max-width: 600px) {
  img.startOver {
    top: 22px;
  }
}

.contentCon form {
  width: 600px;
  max-width: 100%;
  margin: auto;
}

input#radio {
  -webkit-appearance: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px #eee solid;
  background: #ffff;
  border-radius: 50%;
}

input#radio:hover {
  border: 1px #d0d0d0 solid;
}

input[type="radio"]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: 4px;
  left: 4px;
  position: absolute;
  background-color: rgb(7, 182, 169);
  content: "";
  display: inline-block;
  visibility: visible;
}

input:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  h1#questionTitle {
    margin-top: 0px;
    font-size: 22px;
    line-height: 28px;
    padding: 0px 0px 12px;
    white-space: normal;
  }
}

input#myRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  margin-top: -12px;
  border-radius: 50%;
  cursor: pointer;
}

#magicAI {
  margin: auto;
  margin-top: 50px;
  width: 600px;
  max-width: 100%;
}

div#loaderWrap {
  width: 80px;
  margin: auto;
  margin-top: 50px;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(7, 182, 169);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

a#tryAgain {
  color: #2db7a9;
}

#tryAgainWrap {
  text-align: center;
  width: 600px;
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
}

`;

export default CompareGlobalStyle;
