import React from "react";
import jss from "jss";
import preset from "jss-preset-default";
import articleContent from "./ArticleContent";

jss.setup(preset());

const styles = {
  wrap: {
    maxWidth: "800px",
    margin: "auto",
    marginTop: "59px",
    fontSize: "16px",
    lineHeight: "30px",
  },
  title: {
    fontSize: "54px",
    fontWeight: "100",
  },
  images: {
    maxWidth: "100%",
    marginTop: "32px",
    marginBottom: "32px",
    display: "block",
    margin: "auto",
  },
  description: {
    marginTop: "22px",
    fontWeight: "bold",
  },
  byClass: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  autorIcon: {
    borderRadius: "50%",
    margin: "auto",
    marginLeft: "1em",
    marginRight: "1em",
    marginTop: "1em",
    marginBottom: "1em",
    display: "inline-block",
  },
  byAuthor: {
    margin: "auto",
    marginLeft: "-0.2em",
    marginRight: "1em",
    marginTop: "1em",
    marginBottom: "1em",
    display: "inline-block",
  },
  paragraph: {
    marginTop: "32px",
    marginBottom: "32px",
  },
};

const { classes } = jss.createStyleSheet(styles).attach();

export default function Articles(props) {
  const articleUrlTitle = props.match.params.title;

  const article = articleContent.filter(
    (i) => i.articleUrl === articleUrlTitle
  )[0];

  /**
   * Content Validation
   */
  if (
    article !== undefined &&
    article.title &&
    article.description &&
    article.paragraphs &&
    article.title.length > 2 &&
    article.description.length > 2 &&
    article.paragraphs.length > 2
  ) {
    console.log("Article fetched successfully...", article);
  } else {
    window.location = "/";
  }

  return (
    <div className="mainBody">
      <div className={classes.wrap}>
        <div className="contentCon" id="articlePage">
          <h1 id="articleTitle">{article.title}</h1>
          <div className={classes.byClass} id="byClass">
            <img
              className={classes.autorIcon}
              id="autorIcon"
              src="/images/favicon.png"
              alt="Author Icon"
            />
            <div className={classes.byAuthor}>By Exnov system</div>
          </div>
          <div id="articleDescriptn" className={classes.description}>
            {article.description}
          </div>
          <img
            className={classes.images}
            src={article.mainImage}
            alt="article"
          />
          {article.paragraphs.map((i, k) => {
            return article.otherImages[k] ? (
              <div key={k}>
                <div id="paragraph">{article.description}</div>
                <img
                  className={classes.images}
                  src={article.otherImages[k]}
                  alt={"article Image_" + k}
                />
              </div>
            ) : (
              <div className={classes.paragraph} id="paragraph" key={k}>
                {article.paragraphs[k]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
