import React, { Component } from "react";

class Footer extends Component {
  state = {};

  render() {
    return window.location.href.includes("/compare/") ? null : (
      <footer className="page-footer teal">
        <div className="container">
          <div className="row">
            <div className="col l6 s12">
              <h5 className="white-text">
                Fun &amp; Exciting Customized Product Comparison
              </h5>
              <p className="grey-text text-lighten-4">
                Exnov.com is the world’s leading Customized Product Comparison
                platform, where people and organizations can explore and
                discover the perfect customized technology solution for them.
              </p>
            </div>
            <div className="col l3 s12">
              <h5 className="white-text">Exnov</h5>
              <ul>
                <li>
                  <a className="white-text" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="white-text" href="about">
                    About
                  </a>
                </li>
                <li>
                  <a className="white-text" href="contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="col l3 s12">
              <h5 className="white-text">Resources</h5>
              <ul>
                <li>
                  <a className="white-text" href="/blog">
                    Blog
                  </a>
                </li>
                <li>
                  <a className="white-text" href="/privacy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a className="white-text" href="/terms">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <small>© {new Date().getFullYear()} All Rights Reserved.</small>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
