import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as tf from "@tensorflow/tfjs";
import { Helmet } from "react-helmet-async"; // Future develpoment
import CompareGlobalStyle from "./CompareGlobalStyle";

import ReactGA from "react-ga";
ReactGA.initialize("UA-187555257-2");

const Predict = (props) => {
  const [state, setState] = useState({
    headline: "Processing...",
    result: (
      <div id="loaderWrap">
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ),
  });

  //TODO
  // Trigger google page view
  ReactGA.pageview(window.location.pathname + window.location.search);

  const topicId = props.match.params.topic;
  const topicIndex = useSelector((state) =>
    state.compare.topics.findIndex((i) => i.topic === topicId)
  );

  //   const finalAnswers
  const allAnswers = useSelector(
    (state) => state.compare.topics[topicIndex].answers
  );

  // Get the classTypes const classTypes
  const classTypes = useSelector(
    (state) => state.compare.topics[topicIndex].classTypes
  );
  // console.log(classTypes);

  useEffect(() => {
    async function fetchData() {
      //

      if (allAnswers.length === 0) {
        props.history.push("/compare/" + topicId + "/1");
        window.location.href = "/compare/" + topicId + "/1";
      }

      const finalAnswers = Object.values(allAnswers);

      const model = await tf.loadLayersModel(
        process.env.PUBLIC_URL + "/models/" + topicId + "/model.json"
      );

      const prediction = model.predict(
        tf.tensor2d(finalAnswers, [1, allAnswers.length])
      );

      // console.log("Predictor accurate val before:", prediction.dataSync()[0]);
      prediction.dataSync()[0] =
        prediction.dataSync()[0] < 0 ? 0 : prediction.dataSync()[0];

      prediction.dataSync()[0] =
        prediction.dataSync()[0] > classTypes.length - 1
          ? classTypes.length - 1
          : prediction.dataSync()[0];
      // console.log("Predictor accurate val after:", prediction.dataSync()[0]);
      let finalPrediction = Math.round(
        Number(String(prediction.dataSync()[0]).split("").slice(0, 6).join(""))
      );
      // console.log("Final Predictor val:", finalPrediction);

      setState({
        result: (
          <div>
            <h1>Congratulations!</h1>
            <p>We found the perfect match for you.</p>
            <a href={classTypes[finalPrediction]}>
              <button className="bigBtn">My Result</button>
            </a>
          </div>
        ),
      });
    }
    fetchData();
  }, [allAnswers, props, topicId, classTypes]);

  return (
    <div>
      <CompareGlobalStyle />
      <img id="operatorImg" src="/images/operator.png" alt="Operator" />
      <div className="mainBody">
        <div className="contentCon">
          <h1>{state.headline}</h1>
          <div>{state.result}</div>
          <div id="magicAI">
            <h1 id="magich1">A.I Magic in progress...</h1>
            <div id="tryAgainWrap">
              <a id="tryAgain" href={"/compare/" + topicId + "/1"}>
                Try again
              </a>
            </div>
            <p>
              Artificial intelligence (AI) makes it possible for us to estimate
              and predict the best possible option for our customers. Just like
              us, machines learn from experience, adjust to new inputs and
              perform human-like tasks. Machine Learning, Deep learning and many
              more algorithms are changing the world, and you are about to
              experience it firsthand!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Predict;
