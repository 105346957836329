import React, { Component } from "react";
import Axios from "axios";
import theme from "./theme";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";

class Testo extends Component {
  state = {
    content: "",
  };

  componentDidMount() {
    console.log("yep did mount");
    Axios.get(`http://localhost:3001/testoselect/38`).then((response) => {
      this.setState({ content: "ppp" /*response.data[0].name*/ });
      let contentJSON = JSON.parse(response.data[0].name);

      const newContent2 = contentJSON.map((e) => {
        return (
          <div>
            <p>name is {e.name}</p>
            <p>ID is {e.id}</p>
            <p>---</p>
          </div>
        );
      });
      console.log(newContent2);

      this.setState({
        content: newContent2,
      });
    });
  }

  render() {
    const MyFancyButton = styled.button`
      background: ${(props) => props.theme.colors.main};
    `;
    // my comment 06:05
    return (
      <ThemeProvider theme={theme}>
        <div className="mainBody">
          <div>This is testo change</div>
          <MyFancyButton>xxx</MyFancyButton>
          <p>{this.state.content}</p>
        </div>
      </ThemeProvider>
    );
  }
}

export default Testo;
