import React from "react";
import styled from "styled-components";

// style
const Card = styled.a`
  text-decoration: "none";
  color: "rgb(76, 76, 76)";
  margin-top: 10px;
  /* background: blue; */
  height: 180px;
  width: 325px;
  max-width: 90%;
  border-radius: 24px;
  border: 1px solid #dfe1e5;
  scroll-snap-align: start; /*center | end*/
  display: grid;
  align-content: end;
  text-decoration: none;
  color: rgb(76, 76, 76);
`;

const Title = styled.p`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 46px;
  line-height: -0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

export default function NewsWidget({ name, link, imageLink }) {
  // Background image and link from props
  return (
    <Card
      href={"/news/" + name + "/"}
      style={{
        background: `url("${imageLink}") no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <Title>{name}</Title>
    </Card>
  );
}
