import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import store from "./store/store"; // import the store
import { Provider } from "react-redux"; // import the provide

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { RestLink } from "apollo-link-rest";
import { ApolloProvider } from "@apollo/react-hooks";

// https://newsapi.org/v2/everything?from=2021-04-23&sortBy=publishedAt&apiKey=605961ff809446ed93430b99059589bc    &q=Israel

const date = new Date();
date.setDate(date.getDate());
// date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
const restLink = new RestLink({
  uri: "https://bing-news-search1.p.rapidapi.com/news/search?&safeSearch=Off&textFormat=Raw&originalImg=true&freshness=Day&setLang=en",
  headers: {
    "x-bingapis-sdk": "true",
    "x-rapidapi-key": "c0ef9995femshdb5a88483399674p1ac6b1jsnfaa3d700aca4",
    "x-rapidapi-host": "bing-news-search1.p.rapidapi.com",
  },
});

const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const tagManagerArgs = {
  gtmId: "GTM-K9BB8QC",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);
