import { createSlice } from "@reduxjs/toolkit";

export const compareSlice = createSlice({
  name: "compare",
  initialState: {
    category: "default",
    compareBtnText: "News",
    mainCtaText: "News",
    compareBtnURL: "/news/product%20comparison/",
    topics: [
      {
        topic: "Mattresses",
        categoryImage:
          "https://spy.com/wp-content/uploads/2020/06/best-memory-foam-mattresses-of-2020.jpg?w=1000&h=560&crop=1",
        topicTitleS: "Mattress",
        topicTitleP: "Mattresses",
        answers: [],
        classTypes: [
          "https://www.amazon.com/Mattress-Cooling-CertiPUR-US-Certified-Supportive/dp/B07ZKLZF6N/?tag=exnovamazon-20",
          "https://www.amazon.com/Sweetnight-Mattress-Queen-CertiPUR-US-Certified-Isolation/dp/B07QF5FZWY/?tag=exnovamazon-20",
          "https://www.amazon.com/JINGWEI-Mattress-Infused-Premium-Breathable/dp/B0851XWF3P/?tag=exnovamazon-20",
        ],
        chartTitle: "Total Healthcare Visits for back pain",
        chartData: [
          {
            id: "Physician",
            label: "Physician",
            value: 0.74,
            color: "hsl(241, 70%, 50%)",
          },
          {
            id: "Specialist",
            label: "Specialist",
            value: 0.15,
            color: "hsl(307, 70%, 50%)",
          },
          {
            id: "Emergency",
            label: "Emergency",
            value: 0.7,
            color: "hsl(285, 70%, 50%)",
          },
          {
            id: "Hospital",
            label: "Hospital",
            value: 0.4,
            color: "hsl(147, 70%, 50%)",
          },
          // {
          //   id: "php",
          //   label: "php",
          //   value: 308,
          //   color: "hsl(84, 70%, 50%)",
          // },
        ],
        allQuestions: [
          {
            question: "What type of mattress are you looking for?",
            qType: "select",
            options: [
              { text: "Spring / Hybrid", value: 0 },
              { text: "Foam", value: 1 },
              { text: "Not Sure", value: 0.5 },
            ],
            lastQuestion: false,
          },
          {
            question: "What level of firmness do you prefer?",
            qType: "select",
            options: [
              { text: "Soft", value: 0 },
              { text: "Medium", value: 0.5 },
              { text: "Firm", value: 1 },
              { text: "Not Sure", value: 0.5 },
            ],
            lastQuestion: false,
          },
          {
            question: "Do you suffer from back pain?",
            qType: "select",
            options: [
              { text: "No", value: 0 },
              { text: "Yes", value: 1 },
              { text: "Sometimes", value: 0.5 },
            ],
            lastQuestion: false,
          },
          {
            question: "Do you sleep hot?",
            qType: "select",
            options: [
              { text: "No", value: 0 },
              { text: "Yes", value: 1 },
            ],
            lastQuestion: true,
          },
        ],
      },
      {
        topic: "3-pieces-patio-set",
        categoryImage:
          "https://mobilecontent.costco.com/live/resource/img/static-us-collections/patio-park-falls.jpg",
        topicTitleS: "3 pieces patio set",
        topicTitleP: "3 pieces patio sets",
        answers: [],
        classTypes: [
          "https://www.amazon.com/Flamaker-Pieces-Outdoor-Furniture-Conversation/dp/B07DJ6J6GD/ref=zg_bs_3742441_1?_encoding=UTF8&psc=1&refRID=GFW84T2MZSQMFZ9T9PN5&tag=exnovamazon-20",
          "https://www.amazon.com/Devoko-Furniture-Rattan-Cushion-Outdoor/dp/B0765VTBLV/ref=zg_bs_3742441_2?_encoding=UTF8&psc=1&refRID=GFW84T2MZSQMFZ9T9PN5&tag=exnovamazon-20",
          "https://www.amazon.com/Patiorama-Furniture-Conversation-Backyard-Poolside/dp/B087GD8CTJ/ref=zg_bs_3742441_8?_encoding=UTF8&psc=1&refRID=GFW84T2MZSQMFZ9T9PN5&tag=exnovamazon-20",
          "https://www.amazon.com/SONGMICS-3-Piece-Furniture-Assemble-UGGF010B01/dp/B08N4XDQ5G/ref=zg_bs_3742441_39?_encoding=UTF8&psc=1&refRID=GFW84T2MZSQMFZ9T9PN5&tag=exnovamazon-20",
        ],
        chartTitle: "",
        chartData: "",
        allQuestions: [
          {
            question: "How big is your patio?",
            qType: "select",
            options: [
              { text: "Small", value: 0 },
              { text: "Average", value: 0.5 },
              { text: "Large", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "How often do you change furniture?",
            qType: "select",
            options: [
              { text: "Every 1 to 3 years", value: 0 },
              { text: "Every 3 to 6 years", value: 0.5 },
              { text: "Every 7 to 10 years", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "Are you a handyman?",
            qType: "select",
            options: [
              { text: "Yes I am", value: 1 },
              { text: "I'm average", value: 0.5 },
              { text: "No I'm not", value: 0 },
            ],
            lastQuestion: false,
          },
          {
            question: "Does it rain a lot where you live?",
            qType: "select",
            options: [
              { text: "Yes", value: 1 },
              { text: "Sometimes", value: 0.5 },
              { text: "Not at all", value: 0 },
            ],
            lastQuestion: true,
          },
        ],
      },
      {
        topic: "Irons",
        categoryImage:
          "https://hips.hearstapps.com/ame-prod-goodhousekeeping-assets.s3.amazonaws.com/main/embedded/35704/ironing_1.jpg?crop=1.00xw:0.502xh;0,0.498xh&resize=1200:*",
        topicTitleS: "Iron",
        topicTitleP: "Irons",
        answers: [],
        classTypes: [
          "https://www.amazon.com/BLACK-DECKER-IR03V-Steam-Compact/dp/B00A0UF1AO/ref=zg_bs_510242_2?tag=exnovamazon-20",
          "https://www.amazon.com/Steamfast-Travel-Steam-Voltage-1-Pack/dp/B001DTHPEQ/ref=zg_bs_510242_6?tag=exnovamazon-20",
          "https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=zg_bs_510242_8?tag=exnovamazon-20",
          "https://www.amazon.com/Rowenta-DW9280-Stainless-Soleplate-1800-Watt/dp/B00CLD39C0/ref=zg_bs_510242_12?tag=exnovamazon-20",
        ],
        chartTitle: "",
        chartData: "",
        allQuestions: [
          {
            question: "How long does it take you to iron a shirt?",
            qType: "select",
            options: [
              { text: "Fast - Less than 3 minutes", value: 0 },
              { text: "Average - 3 minutes", value: 0.5 },
              { text: "Slow - More than 3 minutes", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "Do you find it hard to iron wrinkles?",
            qType: "select",
            options: [
              { text: "Normally no", value: 0 },
              { text: "Normally yes", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "Are you a technical person?",
            qType: "select",
            options: [
              { text: "Not really", value: 0 },
              { text: "Yes I am", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "Do you suffer from wrist pain?",
            qType: "select",
            options: [
              { text: "No", value: 0 },
              { text: "Sometimes", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "How often do you replace your iron?",
            qType: "select",
            options: [
              { text: "Less than 3 years", value: 0 },
              { text: "More than 3 years", value: 1 },
            ],
            lastQuestion: true,
          },
        ],
      },
      {
        topic: "Medicine-Cabinet",
        categoryImage:
          "https://images.prismic.io/containerstoriesproduction/0e25c4327a2c2efc7dedbebcb8c0838d7208b0cd_howtoorganizeyourmedicinecabinet_1200.jpg?auto=format",
        topicTitleS: "Medicine Cabinet",
        topicTitleP: "Medicine CabinetS",
        answers: [],
        classTypes: [
          "https://www.amazon.com/K-CB-CLR1620FS-Frameless-Aluminum-Bathroom-Installation/dp/B0014XKQWO/ref=zg_bs_3743901_3?tag=exnovamazon-20",
          "https://www.amazon.com/ChooChoo-Bathroom-Multipurpose-Organizer-Furniture-White/dp/B07GFH2Y3R/ref=zg_bs_3743901_4?tag=exnovamazon-20",
          "https://www.amazon.com/K-CB-CLC1526FS-Frameless-Aluminum-Bathroom-Medicine/dp/B0016N6H3Y/ref=zg_bs_3743901_6?tag=exnovamazon-20",
          "https://www.amazon.com/K-CB-CLC2526FS-Frameless-Aluminum-Bathroom-Medicine/dp/B0013ATGJW/ref=zg_bs_3743901_19?tag=exnovamazon-20",
        ],
        chartTitle: "",
        chartData: "",
        allQuestions: [
          {
            question: "What is your average budget?",
            qType: "range",
            minVal: 90,
            maxVal: 150,
            ratio: 150, //factor default is 1
            prefix: "$",
            inputType: null,
            lastQuestion: false,
          },
          {
            question: "How often do you change furniture?",
            qType: "select",
            options: [
              { text: "Every 1 to 3 years", value: 0 },
              { text: "Every 3 to 6 years", value: 0.5 },
              { text: "Every 7 to 10 years", value: 1 },
            ],
            lastQuestion: false,
          },
          {
            question: "Are you a handyman?",
            qType: "select",
            options: [
              { text: "Yes I am", value: 1 },
              { text: "I'm average", value: 0.5 },
              { text: "No I'm not", value: 0 },
            ],
            lastQuestion: false,
          },
          {
            question: "Do you need extra storage Capacity?",
            qType: "select",
            options: [
              { text: "No", value: 0 },
              { text: "Yes", value: 1 },
            ],
            lastQuestion: true,
          },
        ],
      },
    ],
  },
  reducers: {
    changeCategory: (state, action) => {
      state.category = action.payload.category;
      state.compareBtnText = action.payload.compareBtnText;
      state.mainCtaText = action.payload.mainCtaText;
      state.compareBtnURL = action.payload.compareBtnURL;
    },
    addAnswer: (state, action) => {
      state.topics[action.payload.topicIndex].answers = [
        ...state.topics[action.payload.topicIndex].answers,
        Number(action.payload.value),
      ];
    },
  },
});

// Exported the reducers for component => e.g: counter.js
export const { changeCategory, addAnswer } = compareSlice.actions;

// Exported for the store.js
export default compareSlice.reducer;
