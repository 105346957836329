const articleContent = [
  {
    title: "Testo Title",
    articleUrl: "testo-besto",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    paragraphs: [
      "111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "222 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "333 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    ],
    mainImage: "/images/news/testo.gif",
    otherImages: ["/images/news/testo.gif", "/images/news/testo.gif"],
  },
  {
    title: "Old School Product & Delivery Comparison",
    articleUrl: "old-school-product-and-delivery-comparison",
    description:
      "An old school Product Comparison may provide a simple way to compare product features or the product’s capabilities across multiple products. Unfortunately this process is outdated and tedious.",
    paragraphs: [
      "If you are looking to do things the old way, simply start by Determine which products compete directly in the same marketplace as your product. You may want to provide a brief summary of each of these products before commencing the detailed analysis. The summary should include a general product description, its purpose and functionality.",
      "As for step 2, Just like a Competitor Analysis, Product Comparisons require research. Use available material such as marketing collateral and the web as the basis of your research.",
      "However, if possible you may want to purchase or subscribe to products that you are comparing. This will give you an opportunity to complete the subscription or purchase process and experience the product as a customer. Using the product will give you the ability to test the product features and determine if the features are useful, a hinderance or just nice to haves.",
      "Depending on the type of product, you may want to grab a few images and or record the use of the product. Its good to refer to the visuals time and time again to ensure that you’ve understood the detailed functions of the product.",
      "There are different types of Product Comparison tables that you can use to evaluate one product from another. Qualitative Product Comparison table, Quantitative Product Comparison table and finally Product Comparison checklist",
      "The Qualitative Product Comparison table compares products in a rudimentary way. The features of each product for each competitor are listed and compared. The “best” product feature receives a score of 1, while the other products receive a score of 0. The Product Manager’s role is to list the feature and assign the score. The win/loss score is subsequently tallied to determine which product has the most “winning” features.",
      "The Quantitative Product Comparison table has more depth than the Qualitative Product Comparison table. Each feature listed in the table for each competitor is assigned a weight (by the Product Manager) designating its relative importance. Scores from 1 to 10 are given to each product (by the Product Manager) at the feature level. The total score is tallied by summing the results of each weight multiplied by the score.",
      "The Product Comparison checklist simply lists each feature and determines which product has this feature.",
      "As you can see we’ve got a good understanding of your competitors’ product features, you’ll need to establish and present a conclusion. Part of your conclusion should determine if your product requires additional features. Be mindful not to simply add features because your competitor’s product has more features than your product. It’s still important to consider product features in light of your customers’ needs.",
    ],
    mainImage: "/images/news/pc1.jpeg",
    otherImages: ["/images/news/pc2.png", "/images/news/pc3.jpeg"],
  },
  {
    title: "The Truth About Amazon Reviews",
    articleUrl: "the-truth-about-amazon-reviews",
    description:
      "So you decide to skip the research part and just read reviews…… One of the greatest things about Amazon is also one of its biggest problems: the reviews. Can you trust Amazon reviews? More importantly, can the reviews really help you buy a product you'll love? The answer, as with so many things, is \"it depends.\" You have to be savvy about product reviews. You have to learn what to pay attention to, what to ignore, and what you can and can't believe--and why.",
    paragraphs: [
      "Since we started The Rational Kitchen, we've immersed ourselves in online reviews and Amazon reviews in particular. We can find ourselves frustrated because reviews just aren't very helpful sometimes. And it often isn't an issue of dishonesty or willful deception. People give mediocre products 5-star reviews for a lot of reasons, and sometimes the reasons have little to do with a product's quality.",
      "Buying products based on overly positive reviews can result in just as much frustration and disappointment as buying products based on reviews written with willful deception.",
      "This is especially true for technical products that you don't understand very well and don't know how to evaluate. If you're not sure what to look for, then most other people are probably in the same boat, including those who've already bought the product and already written a review!",
      "You can learn how to use Amazon reviews, as well as other sources of information, to buy wisely and find products you can truly love. But you have to know how to spot fake reviews, and you have to know how to evaluate honest reviews so you know if you can trust what the person is saying.",
      "Here, we talk about the two main categories of unhelpful reviewers: 1) fake reviews and 2) honest-but-unhelpful reviews. Then we talk about how to analyze Amazon reviews to get the most useful information you can, as well as when and how to use external review sites to help you buy as wisely as possible.",
      'Do\'nt forget there are also Fake Amazon Reviews. There are two types of fake reviews: the "in exchange for an honest review" review, and the blatantly fake review."In Exchange for an Honest Review" Reviews. User reviews are a huge part of why people buy what they buy on Amazon. A lot of positive reviews equates to a lot of sales. This being the case, sellers have learned to game the system.',
      'One way they do this is to give away or greatly discount products "in exchange for an honest review." These are easy to spot because the review will contain a disclosure statement along the lines of "I received this product in exchange for my honest review." If you see a statement like this somewhere in the review (usually at the end), don\'t give the review too much weight (unless it\'s negative, in which case it\'s probably very honest, indeed).',
      'These aren\'t exactly "fake" reviews, but you mostly can\'t trust them, nevertheless. Research has shown that these "in exchange" reviews are overwhelmingly more positive than reviews freely given. So proceed with caution if there are a lot of these types of reviews, especially if they are mostly positive.',
      "Another thing sellers do is use review mills to generate reviews--yes, there are actually businesses that will write fake product reviews for profit. Paid reviewers have been used for awhile by marketers and manufacturers to create buzz about their products on Amazon, and probably elsewhere, too. These may not be a big issue anymore. In 2015, Amazon filed a number of lawsuits against these paid review companies, and a lot of them have shut down. But it's quite possible, even probable, that sellers have found new ways to game the system, because where there's money to be made, disreputable sellers can usually find ways to do it. So you should still be careful when deciding which reviews to trust.",
    ],
    mainImage: "/images/news/ar1.jpeg",
    otherImages: ["/images/news/ar2.jpeg", "/images/news/ar3.jpeg"],
  },
  {
    title: "Types of Mattresses Explained",
    articleUrl: "types-of-mattresses-explained",
    description:
      "As you may already know shopping for a new mattress may be confusing. Mattress makers and retailers know it. In fact, the more confused you are, the better it is for them. Here's the straight truth about mattresses.",
    paragraphs: [
      "When you buy a mattress at a traditional store or a corner sleep shop, you're paying for a lot more than springs and foam. You're paying for big marketing budgets, overdone showrooms with aromatherapy displays (a tricky way to keep you calm about the prices), and a metric ton of overhead. When mattress makers change their covers or give their memory foam a new high-tech name, you end up paying more for that, too.",
      "INNERSPRING: Innerspring mattresses contain layers of spring coils, providing firm support and high durability. This type of construction has been used by mattress makers for decades in their patented processes. Aireloom of Beverly Hills makes luxury innerspring mattresses by hand, using long-staple cotton, wool, and pure silk. The Dump offers handmade Aireloom mattresses up to 70% off the price of high-end stores.",
      "MEMORY FOAM: Memory foam mattresses are renowned for a high level of support while conforming to one's body for exceptional comfort. These mattresses begin with a base layer of support foam; the next layer contains anywhere from 1-7 inches of memory foam. The prices of memory foam mattresses at The Dump range from $209 (mattress only) to $4800 (mattress sets). Generally, higher priced foam mattresses have thicker memory foam layers. The attractive price and body-hugging comfort of The Somerset mattress by myLOFT make it a favorite of Dump customers.",
      "LATEX: Latex mattresses are available from very soft to firm. These mattresses are made of 1) 100% natural latex or 2) a combination of natural latex and foam or springs for the base layer. Covers on latex mattresses often contain other natural and organic materials such as cotton or bamboo, while other covers include non-organic cotton or other breathable materials. Mattresses from James & Owen and PranaSleep found at The Dump are some of the highest quality latex mattresses made.",
      "HYBRID: True hybrid mattresses contain innersprings with at least 50% of the overall thickness in memory foam. These mattresses take the best of spring construction (support, lack of sinking feeling) and memory foam models (conformance to individual body type and weight, limited bounce) and present them in one elegant mattress. The iComfort mattresses are some of the best hybrid mattresses we've ever seen. And we think you'll enjoy them even more at our off-price discounts.",
      "PILLOW TOP: Pillow top or euro-top mattresses are for those who want the luxury of an extra layer of softness. These mattresses feature an additional plush layer on top of the support layer for added comfort. Pillow top mattresses such as The Tori and The Melodic from our exclusive iTwin Collection offer that perfect blend of plush softness and support.</p>",
    ],
    mainImage: "/images/news/m1.jpeg",
    otherImages: ["/images/news/m2.jpeg", "/images/news/m3.jpeg"],
  },
];

export default articleContent;
