import React from "react";
import "./HomePageCards.css";

export default function HomePageCards() {
  const handleClick = (link) => {
    window.location = link;
  };

  return (
    <div className="HomePageCards">
      <h2>Customized Product Comparison</h2>
      <div id="cardsDescription">
        We provide a cross-industry, customized product comparison service
        tailored for your needs. We give our users true guidance, unlike
        traditional product comparison websites. The experience is easy,
        enjoyable and most importantly accurate and insightful. We are here to
        help you reach your Goals.
      </div>
      <div className="hpc">
        <div
          id="cardImage"
          style={{
            background:
              "url(/images/homePageCards/mattress.png) no-repeat center center",
            backgroundSize: "contain",
          }}
        ></div>
        <h2>Mattresses</h2>
        <p>Shopping for a mattress has never been easier</p>
        <div className="wrapCardButton">
          <div
            className="bigBtn"
            onClick={() => handleClick("/Explore/Mattresses/")}
          >
            Learn More
          </div>
        </div>
      </div>
      <div className="hpc">
        <div
          id="cardImage"
          style={{
            background:
              "url(/images/homePageCards/terrace.png) no-repeat center center",
            backgroundSize: "contain",
          }}
        ></div>
        <h2>Patio sets</h2>
        <p>Find the perfect patio furniture for you</p>
        <div className="wrapCardButton">
          <div
            className="bigBtn"
            onClick={() => handleClick("/Explore/3-pieces-patio-set/")}
          >
            Learn More
          </div>
        </div>
      </div>
      <div className="hpc">
        <div
          id="cardImage"
          style={{
            background:
              "url(/images/homePageCards/iron.png) no-repeat center center",
            backgroundSize: "contain",
          }}
        ></div>
        <h2>Irons</h2>
        <p>keep your clothes wrinkle-free and look fresh</p>
        <div className="wrapCardButton">
          <div
            className="bigBtn"
            onClick={() => handleClick("/Explore/Irons/")}
          >
            Learn More
          </div>
        </div>
      </div>
      <div className="hpc">
        <div
          id="cardImage"
          style={{
            background:
              "url(/images/homePageCards/medicine-cabinet.png) no-repeat center center",
            backgroundSize: "contain",
          }}
        ></div>
        <h2>Medicine Cabinet</h2>
        <p>Discover your new perfect storage space</p>
        <div className="wrapCardButton">
          <div
            className="bigBtn"
            onClick={() => handleClick("/Explore/Medicine-Cabinet/")}
          >
            Learn More
          </div>
        </div>
      </div>
    </div>
  );
}
