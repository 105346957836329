import React, { useState } from "react";
import "./News.css";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import NewsTopics from "./NewsTopics";

// note for single end point remove endpoint: "v1"
const CONTENT_QUERY = gql`
  query Content($path: String!) {
    content @rest(type: "Content", path: $path) {
      value
    }
  }
`;

export default function News(props) {
  const topicId = props.match.params.topic;

  const [state, setstate] = useState("");

  const handleChange = (e) => {
    setstate(e.target.value);
  };
  const onSearchClick = () => {
    if (state !== "") {
      window.location = `/news/${state}/`;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      window.location = `/news/${state}/`;
    }
  };

  const { loading, error, data } = useQuery(CONTENT_QUERY, {
    variables: {
      path: `&q=${topicId}`, // path: `posts/${id}`,
    },
  });

  console.log(data);
  const content =
    data !== undefined
      ? data.content.value.map((a, i) => {
          return a.image ? (
            <a href={a.url}>
              <div className="articleCard" key={i}>
                <h3 id="title">{a.name}</h3>
                <p id="url">{a.url}</p>
                <p id="description">{a.description}</p>
                {a?.image?.contentUrl ? (
                  <img src={a.image.contentUrl} alt="articleImage" />
                ) : null}
              </div>
            </a>
          ) : null;
        })
      : "No content";

  return (
    <div className="mainBody">
      <div className="contentCon" id="newsPage">
        <h1>News</h1>
        <div id="inputWrap">
          <input
            id="newsInput"
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <button
            className="btn-search"
            type="button"
            onClick={(e) => onSearchClick(e)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
          </button>
        </div>
        <div className="contentWrap">
          <NewsTopics />
          {loading === false ? content : <p>Loading...</p>}
        </div>
      </div>
    </div>
  );
}
