import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeCategory } from "./store/compareSlice";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

function Discover(props) {
  const dispatch = useDispatch();
  dispatch(
    changeCategory({
      category: "Website Builder",
      compareBtnText: "Compare",
      mainCtaText: "Compare Builders",
      compareBtnURL: "compare/Website_Builder/1",
    })
  );
  const category = useSelector((state) => state.compare.category);
  const compareBtnText = useSelector((state) => state.compare.compareBtnText);
  const mainCtaText = useSelector((state) => state.compare.mainCtaText);
  const compareBtnURL = useSelector((state) => state.compare.compareBtnURL);

  console.log(category, compareBtnText, mainCtaText, compareBtnURL);

  const [st, seSt] = useState({
    meta_title: "Best Website Builder 2021 | Customized Comparison",
    meta_description:
      "Fun and Exciting Customized Website Builder Comparison. Find the Best Website Builder For You! Discover Exnov, the world’s leading Customized Product Comparison platform.",
    meta_image: window.location.origin + "/images/logo.png",
    meta_url: window.location.href.split("?")[0], //location.href.split('?')[0]
    articles: [], //null,
  });

  useEffect(() => {
    fetch(
      "https://www.exnov.com/blog/?rest_route=/wp/v2/posts&include[]=56&include[]=46&include[]=37&include[]=17&include[]=15&include[]=13&include[]=11&include[]=6"
    )
      .then((response) => response.json())
      .then((posts) => {
        // console.log('article fetches successfully');
        seSt({
          articles: posts,
        });
        // console.log(st.articles);
      });
  }, []);

  const handleGaEvent = (e, category, action, value) => {
    //e.preventDefault();
    // console.log(category, action, value)
    ReactGA.event({
      category: category,
      action: action,
      value: value,
    });
  };

  let artKey = 0;
  const articlesSection = st.articles.map((article) => {
    let articleName = article["title"]["rendered"]
      .split(" ")
      .slice(0, 1)
      .join(" ");
    let articleImg = article["jetpack_featured_media_url"];
    let html = article["content"]["rendered"];
    let div = document.createElement("div");
    div.innerHTML = html;
    let text = div.textContent || div.innerText || "";
    let articleDescription =
      text
        .split(" ")
        .slice(0, 200)
        .join(" ")
        .replace(/(\r\n|\n|\r)/gm, "") + "...";

    return (
      <div className="contentCon" key={artKey++}>
        <img src={articleImg} className="compareArticleImage" alt="Article" />
        <a href={compareBtnURL}>
          <h3>{articleName}</h3>
        </a>
        <p className="p3">{articleDescription}</p>
        <a
          href={compareBtnURL}
          onClick={(e) => {
            handleGaEvent(e, "mainButtonClick", "Compare " + articleName, 1);
          }}
        >
          <div className="bigBtn">{mainCtaText}</div>
        </a>
      </div>
    );
  });

  return (
    <div>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{st.meta_title}</title>
        <meta name="title" content={st.meta_title} />
        <meta name="description" content={st.meta_description} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={st.meta_url} />
        <meta property="og:title" content={st.meta_title} />
        <meta property="og:description" content={st.meta_description} />
        <meta property="og:image" content={st.meta_image} />

        {/* Twitter */}
        <meta property="twitter:card" content={st.meta_image} />
        <meta property="twitter:url" content={st.meta_url} />
        <meta property="twitter:title" content={st.meta_title} />
        <meta property="twitter:description" content={st.meta_description} />
        <meta property="twitter:image" content={st.meta_image} />

        <link rel="stylesheet" href="/css/Discover.css" />
      </Helmet>
      <div className="mainBody">
        <div className="contentCon">
          <h1 id="bigTitleArticle">Best Website Builder 2021</h1>
          <p>
            Only few clicks. Customized Product comparison.
            <br />
            Fun and Exciting.
          </p>
          <a
            href={compareBtnURL}
            onClick={(e) => {
              handleGaEvent(
                e,
                "mainButtonClick",
                "Website Builder Comparison",
                1
              );
            }}
          >
            <div className="bigBtn">compare builders</div>
          </a>
          <img
            className="contImgTmp"
            src="images/h1.png"
            alt="content temp img"
          />
        </div>

        <div className="contentCon" id="secondDiv">
          <p>
            Forget Everything You Know About Website Buildersconsole. Fun and
            Exciting Customized Website Builder Comparison. Find the Best
            Website Builder For You! Discover Exnov, the world’s leading
            Customized Product Comparison platform.
          </p>
          <a
            href={compareBtnURL}
            onClick={(e) => {
              handleGaEvent(
                e,
                "mainButtonClick",
                "Website Builder Comparison",
                1
              );
            }}
          >
            <div className="bigBtn">compare builders</div>
          </a>
          {/* <img
              className="contImgTmp"
              src="images/h2.png"
              alt="content temp img"
            /> */}
        </div>

        {articlesSection}
      </div>
    </div>
  );
}

export default Discover;
