import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./clouds.css";
import "./HomePageCards";
import HomePageCards from "./HomePageCards";

class Home extends Component {
  state = {
    meta_title: "Product Comparison, Customized and Easy | Exnov",
    meta_description:
      "Fun and Exciting Customized Product Comparison, Exnov.com is the world’s leading Customized Product Comparison platform, where people and organizations can explore and discover the perfect customized technology solution for them.",
    meta_image: window.location.origin + "/images/logo.png",
    meta_url: window.location.href.split("?")[0], //location.href.split('?')[0]
  };

  handleGaEvent = (e, category, action, value) => {
    //e.preventDefault();
    // console.log(category, action, value)
    ReactGA.event({
      category: category,
      action: action,
      value: value,
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          {/* Primary Meta Tags */}
          <title>{this.state.meta_title}</title>
          <meta name="title" content={this.state.meta_title} />
          <meta name="description" content={this.state.meta_description} />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={this.state.meta_url} />
          <meta property="og:title" content={this.state.meta_title} />
          <meta
            property="og:description"
            content={this.state.meta_description}
          />
          <meta property="og:image" content={this.state.meta_image} />

          {/* Twitter */}
          <meta property="twitter:card" content={this.state.meta_image} />
          <meta property="twitter:url" content={this.state.meta_url} />
          <meta property="twitter:title" content={this.state.meta_title} />
          <meta
            property="twitter:description"
            content={this.state.meta_description}
          />
          <meta property="twitter:image" content={this.state.meta_image} />
        </Helmet>
        <div className="mainBody">
          <div className="contentCon">
            <h1 id="bigTitle">
              Forget Everything You Know About Product Comparison
            </h1>
            <p>
              We are here to help you find the perfect solution for you, that is
              why we invented the world’s first truly existing customized
              comparison platform.
            </p>
            <div id="homeImgWrapper">
              <img
                src="images/cloud.svg"
                className="clouds"
                id="cloudOne"
                alt="cloud"
              />
              <img
                src="images/cloud.svg"
                className="clouds"
                id="cloudTwo"
                alt="cloud"
              />
              <img
                src="images/cloud.svg"
                className="clouds"
                id="cloudThree"
                alt="cloud"
              />
              <img
                className="contImgTmp"
                src="images/h1.png"
                alt="content temp img"
              />
            </div>
          </div>
          <HomePageCards />
          <div className="contentCon">
            <h2>Old School Product & Delivery Comparison</h2>
            <p>
              An old school Product Comparison may provide a simple way to
              compare product features or the product’s capabilities across
              multiple products. Unfortunately this process is outdated and
              tedious...
            </p>
            <a
              href="/articles/old-school-product-and-delivery-comparison/"
              onClick={(e) => {
                this.handleGaEvent(e, "mainButtonClick", "Wix Article", 1);
              }}
            >
              <div className="bigBtn">Read More</div>
            </a>
            <img
              className="contImgTmp"
              src="images/h2.png"
              alt="content temp img"
            />
          </div>

          <div className="contentCon">
            <h2>The Truth About Amazon Reviews</h2>
            <p>
              One of the greatest things about Amazon is also one of its biggest
              problems: Amazon Reviews. Can you trust Amazon reviews? More
              importantly, can the reviews really help you buy a product you'll
              love?...
            </p>
            <a
              href="/articles/the-truth-about-amazon-reviews/"
              onClick={(e) => {
                this.handleGaEvent(e, "mainButtonClick", "Shopify Article", 1);
              }}
            >
              <div className="bigBtn">Read More</div>
            </a>
            <img
              className="contImgTmp"
              src="images/h3.png"
              alt="content temp img"
            />
          </div>

          <div className="contentCon">
            <h2>Types of Mattresses Explained</h2>
            <p>
              As you may already know shopping for a new mattress may be
              confusing. Mattress makers and retailers know it! In fact, the
              more confused you are, the better it is for them. Here's the
              straight truth about mattresses...
            </p>
            <a
              href="/articles/types-of-mattresses-explained/"
              onClick={(e) => {
                this.handleGaEvent(e, "mainButtonClick", "Gator Article", 1);
              }}
            >
              <div className="bigBtn">Read More</div>
            </a>
            <img
              className="contImgTmp"
              src="images/h4.png"
              alt="content temp img"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
