import React, { Component } from "react";
import Recaptcha from "react-recaptcha";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
import "./contact.css";

class Contact extends Component {
  state = {
    capVal: false,
    mainFormId: "",
    thankYouId: "hideComponent",
    nameErrorId: "hideComponent",
    emailErrorId: "hideComponent",
    messageErrorId: "hideComponent",
    meta_title: "Contact | Exnov",
    meta_description:
      "Contact Exnov.com the world’s leading Customized Product Comparison platform, where people and organizations can explore and discover the perfect customized technology solution for them.",
    meta_image: window.location.origin + "/images/logo.png",
    meta_url: window.location.href.split("?")[0]
  };

  handlerSendEmail = (e) => {
    e.preventDefault();
    let subStatus = true;
    // console.log('Send handler was called');

    if (this.state.capVal === false) {
      subStatus = false;
      // console.log('Status changed to false');
    }

    var nameValue = document.getElementsByClassName("validate")[0].value;
    if (nameValue.length < 2 || /\d/.test(nameValue) === true) {
      subStatus = false;
      this.setState({ nameErrorId: "" });
      // console.log('name is false');
    } else {
      this.setState({ nameErrorId: "hideComponent" });
    }

    var emailValue = document.getElementsByClassName("validate")[1].value;
    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (validateEmail(emailValue) === false) {
      subStatus = false;
      this.setState({ emailErrorId: "" });
      // console.log('email is false');
    } else {
      this.setState({ emailErrorId: "hideComponent" });
    }

    var messageValue = document.getElementsByClassName("validate")[2].value;
    if (messageValue.length < 5) {
      subStatus = false;
      this.setState({ messageErrorId: "" });
      // console.log('message is false');
    } else {
      this.setState({ messageErrorId: "hideComponent" });
    }

    if (subStatus === true) {
      // console.log('trigger send email');
      emailjs
        .sendForm(
          "service_47kz7ep",
          "template_bbf33cv",
          e.target,
          "user_e3FCgU9ZWpiRCJf0qF5lI"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      this.setState({ mainFormId: "hideComponent" });
      this.setState({ thankYouId: "" });
      window.scrollTo(0, 0);
    }
  };

  verifyCallback = (e) => {
    // alert('call back') //
    this.setState({
      capVal: true
    });
  };

  onCapLoad = (e) => {
    // console.log("reCaptcha was called back");//
  };

  render() {
    return (
      <div>
        <Helmet>
          {/* Primary Meta Tags */}
          <title>{this.state.meta_title}</title>
          <meta name="title" content={this.state.meta_title} />
          <meta name="description" content={this.state.meta_description} />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={this.state.meta_url} />
          <meta property="og:title" content={this.state.meta_title} />
          <meta
            property="og:description"
            content={this.state.meta_description}
          />
          <meta property="og:image" content={this.state.meta_image} />

          {/* Twitter */}
          <meta property="twitter:card" content={this.state.meta_image} />
          <meta property="twitter:url" content={this.state.meta_url} />
          <meta property="twitter:title" content={this.state.meta_title} />
          <meta
            property="twitter:description"
            content={this.state.meta_description}
          />
          <meta property="twitter:image" content={this.state.meta_image} />
        </Helmet>
        <div className="mainBody">
          <div className="contentCon">
            <div className="thankYou" id={this.state.thankYouId}>
              <br />
              <br />
              <h1>Thank you!</h1>
              <p>Your message has been sent successfully!</p>
              <img
                className="roboDance"
                alt="page not found"
                src="images/sendMail.gif"
              ></img>
            </div>
            <div id={this.state.mainFormId}>
              <h1>Contact Us</h1>
              <p>
                Contact Exnov.com the world’s leading Customized Product
                Comparison platform, where people and organizations can explore
                and discover the perfect customized technology solution for
                them.
              </p>
              <br />
              <br />
              <form className="enxContactWrap" onSubmit={this.handlerSendEmail}>
                <label>Name</label>
                <input
                  type="text"
                  name="user_name"
                  id="user_name"
                  className="validate"
                />
                <p className="errorMessage" id={this.state.nameErrorId}>
                  Please enter a valid name.
                </p>
                <br />
                <br />
                <label>Email</label>
                <input type="email" name="user_email" className="validate" />
                <p className="errorMessage" id={this.state.emailErrorId}>
                  Please enter a valid Email.
                </p>
                <br />
                <br />
                <label>Message</label>
                <textarea name="message" className="validate" />
                <p className="errorMessage" id={this.state.messageErrorId}>
                  Please enter a valid Message.
                </p>
                <br />
                <br />
                <Recaptcha
                  sitekey="6LccEyUTAAAAAGgjYbJ3BK9bh59sLi4unqeo2uoP"
                  render="explicit"
                  onloadCallback={this.onCapLoad}
                  verifyCallback={this.verifyCallback}
                />
                <br />
                <input
                  className="waves-effect waves-light btn-small"
                  type="submit"
                  value="Send"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
